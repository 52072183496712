import toPIPs from '@/mixins/toPIPs'
import instrumentCheck from '@/mixins/instrumentCheck'
import to from '@/helpers/time'

export default async function processSignals(data) {
  const signals = []
  data.forEach(x => {
    const now = Date.now()
    const timeSinceEntry = to.minutes({ time: now - x.timestamp, from: 'miliseconds' }).output
    if (timeSinceEntry < 60) {
      const instrument = x.strategy.instrument.name
      const parsedTP = parseFloat(x.trade.tp)
      const parsedSL = parseFloat(x.trade.sl)
      const parsedAct = parseFloat(x.trade.activation) * 100
      const parsedAvgAct = parseFloat(x.metric.avg_activation) * 100
      const avgReturn = parseFloat(x.metric.avg_return)
      const totalReturn = parseFloat(x.metric.total_return)
      const pipsTP = toPIPs(instrument, parsedTP)
      const pipsSL = toPIPs(instrument, parsedSL)
      const tpPrice = instrumentCheck(instrument, x.trade.entry_price, parsedTP)
      const slPrice = instrumentCheck(instrument, x.trade.entry_price, parsedSL)
      const entryPrice = instrumentCheck(instrument, x.trade.entry_price, 0)
      const totalTrades = +x.metric.trades_won + +x.metric.trades_lost
      const accuracy = Math.round((x.metric.trades_won / totalTrades) * 1000) / 10
      // for displaying new signals on trading page
      const strategyType = x.strategy.method.name

      signals.push({
        id: x.id,
        strategyType,
        action: String(x.trade.decision),
        result: x.trade.return,
        instrument,
        entryPrice,
        entryTime: x.trade.entry_time,
        signalTime: x.timestamp,
        tp: tpPrice,
        sl: slPrice,
        pipsSL,
        pipsTP,
        wins: x.metric.trades_won,
        losses: x.metric.trades_lost,
        totalTrades,
        accuracy,
        avgReturn,
        totalReturn,
        activation: parsedAct.toFixed(2),
        avgActivation: parsedAvgAct.toFixed(2),
      })
    }
  })
  return signals
}
