function hours({ time, from }) {
  let output
  if (from === 'minutes') output = time / 60
  if (from === 'seconds') output = time / 3600
  if (from === 'miliseconds') output = time / 3600000
  if (from === 'microseconds') output = time / 3600000000
  return {
    output,
  }
}

function minutes({ time, from }) {
  let output
  if (from === 'hours') output = time * 60
  if (from === 'seconds') output = time / 60
  if (from === 'miliseconds') output = time / 60000
  if (from === 'microseconds') output = time / 60000000
  return {
    output,
  }
}

function seconds({ time, from }) {
  let output
  if (from === 'hours') output = time * 3600
  if (from === 'minutes') output = time * 60
  if (from === 'miliseconds') output = time / 1000
  if (from === 'microseconds') output = time / 1000000
  return {
    output,
  }
}

const to = {
  minutes,
  hours,
  seconds,
}

export default to
