import {
  ref,
  computed,
} from '@vue/composition-api'
// import instruments from '@/data/instruments.json'
import { gql } from '@apollo/client/core'
import useApollo from '@/plugins/apolloClient'
import processSignals from './processSignals'
import useInstruments from '../useInstruments'
import signalObject from './signalObject'
import useJournalTrades from '../journal/useJournalTrades'

const signals = ref({
  signalsSocketOpen: false,
  live: [],
})

export default function useSignals() {
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const { instrumentFilter, instrumentActive } = useInstruments()
  const { journalIds } = useJournalTrades()
  const { sock } = useApollo()

  async function getSignals() {
    sock.value.request({
      query: gql`
        subscription liveSignalsSocket {
          ${frontSchema}_signals(limit: 25,
            order_by: {timestamp: desc},
            where: {trade: {return: {_eq: 0}}})
          ${signalObject}
        }
      `,
    }).subscribe({
      next: async sig => {
        const processed = await processSignals(sig.data[`${frontSchema}_signals`])
        signals.value.live = processed
      },
      error: err => console.error('error with live signals', err),
    })
  }

  function signalFilter(data) {
    // filter against active journal trades
    const signalUntaken = data.filter(signal => !journalIds.value.includes(signal.id))
    // filter by instrument
    if (instrumentFilter.value) {
      return signalUntaken.filter(signal => signal.instrument === instrumentActive.value.name)
    } return signalUntaken
  }

  function setSignalWebsocketOpen() {
    signals.value.signalsSocketOpen = true
  }

  return {
    signals: computed(() => signalFilter(signals.value.live)),
    signalsWebsocket: {
      open: getSignals,
      setOpen: setSignalWebsocketOpen,
      isOpen: signals.value.signalsSocketOpen,
    },
  }
}
