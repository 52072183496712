const signalObject = `{
  id
  strategy_id
  testing_metrics_id
  timestamp
  trade_id
  training_metrics_id
  trade {
    activation
    decision
    entry_price
    entry_time
    exit_price
    exit_time
    id
    metrics_id
    return
    revenue
    sl
    tp
  }
  metric {
    avg_activation
    avg_max_neg
    avg_max_pos
    avg_return
    avg_revenue
    avg_sl
    avg_tp
    begin_time
    dataset_size
    end_time
    id
    max_sl
    max_tp
    min_sl
    min_tp
    stdev_activation
    stdev_max_neg
    stdev_max_pos
    stdev_revenue
    stdev_sl
    stdev_tp
    total_return
    total_revenue
    trades_lost
    trades_won
  }
  strategy {
    instrument {
      id
      name
    }
    method {
      name
    }
  }
}`

export default signalObject
