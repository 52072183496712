import {
  ref,
  computed,
  watch,
} from '@vue/composition-api'
import instruments from '@/data/instruments.json'
import useJournalTrades from '../journal/useJournalTrades'
import useTrades from './useTrades'
import useInstruments from '../useInstruments'
import useRates from '../rates/useRates'

const plot = ref({
  type: 'null',
  analysis: JSON.parse(JSON.stringify(instruments.number)), // for trade index
  strategies: JSON.parse(JSON.stringify(instruments.number)),
  journal: JSON.parse(JSON.stringify(instruments.number)),
  rates: [],
})

export default function useTradePlot() {
  const { journalTrades } = useJournalTrades()
  const { trades, tradesTesting } = useTrades()
  const { instrumentActive, instrumentFilter } = useInstruments()
  const { getRatesFromTo } = useRates()

  const inst = computed(() => {
    if (!instrumentFilter.value) return 'ALL'
    return instrumentActive.value.name
  })

  const type = computed(() => plot.value.type)
  const plotIndex = computed(() => plot.value[type.value][inst.value])

  const tradeLength = computed(() => {
    let l = null
    if (type.value === 'journal') l = journalTrades.value.length
    else if (type.value === 'strategies') l = tradesTesting.value.length
    else if (type.value === 'analysis') l = trades.value.length
    return l
  })

  const plotTrade = computed(() => {
    let t = null
    if (type.value === 'journal') t = journalTrades.value[plotIndex.value]
    else if (type.value === 'strategies') t = tradesTesting.value[plotIndex.value]
    else if (type.value === 'analysis') t = trades.value[plotIndex.value]
    return t
  })

  async function setPlotTrade({ plotType, trade }) {
    let index
    if (plotType === 'journal') index = await journalTrades.value.findIndex(t => trade.id === t.id)
    else if (plotType === 'strategies') index = 0
    else index = await trades.value.findIndex(t => trade.id === t.id)
    plot.value[plotType][inst.value] = index
    plot.value.type = plotType
  }

  async function incrementPlotIndex(value) {
    let index = plot.value[type.value][inst.value]
    if (index <= tradeLength.value - 2 && value > 0) {
      index += value
    } else if (index > 0 && value < 0) {
      index += value
    }
    plot.value[type.value][inst.value] = index
  }

  function calculateEntryTime(trade) {
    const timestamp = trade.entryTime.user || trade.entryTime
    const date = new Date(timestamp)

    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    })
  }

  watch(() => plotTrade.value, async () => {
    if (plotTrade.value) {
      const rates = await getRatesFromTo({ trade: plotTrade.value })
      plot.value.rates = rates
    }
  })

  const mets = ref({
    type: computed(() => type.value),
    time: computed(() => calculateEntryTime(plotTrade.value)),
    index: computed(() => plotIndex.value + 1),
    length: computed(() => tradeLength.value),
  })

  return {
    plotTrade,
    plotMetrics: computed(() => mets.value),
    plotRates: computed(() => plot.value.rates),
    setPlotTrade,
    incrementPlotIndex,
  }
}
