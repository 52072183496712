<template>
  <div class="chart-component">
    <div class="chart-top-bar">
      <div class="top-bar-left">
        <InstrumentDropdown class="small" />
        <div class="chart-option">
          <i
            id="expandGrid"
            class="fas sm fa-arrow-up chart-icon"
            @click="expandGrid"
          />
          <i
            id="expandChart"
            class="fas sm fa-arrow-down chart-icon"
            @click="expandChart"
          />
          <i
            class="fas sm fa-arrow-right chart-icon"
            @click="resetChart"
          />
          <!-- <i
            id="displaySignals"
            class="fas sm fa-rss chart-icon clicked"
            @click="displaySignals"
          /> -->
        </div>
      </div>
      <div class="top-bar-right">
        <p id="time">
          {{ clockTime }}
        </p>
      </div>
    </div>
    <div
      ref="chartWrap"
      class="chart-wrapper"
      @click="checkRateRange"
      @wheel="checkRateRange"
    >
      <resize-observer @notify="handleResize" />
      <Loader v-if="ratesLoading.blocking" />
      <trading-vue
        v-else
        ref="tradingVue"
        :data="dc"
        :overlays="overlays"
        :color-back="colors.back"
        :color-grid="colors.grid"
        :color-text="colors.text"
        :color-cross="colors.cross"
        :color-candle-up="colors.candle_up"
        :color-candle-dw="colors.candle_dw"
        :color-wick-up="colors.wick_up"
        :color-wick-dw="colors.wick_dw"
        :color-vol-up="colors.volume_up"
        :color-vol-dw="colors.volume_dw"
        :index-based="true"
        :chart-config="config"
        :height="chartHeight"
        :width="chartWidth"
      />
    </div>
  </div>
</template>

<script>
import { TradingVue, DataCube } from 'trading-vue-js'
import Ripple from 'vue-ripple-directive'
import { ResizeObserver } from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import {
  onUnmounted,
  watch,
  ref,
  onMounted,
} from '@vue/composition-api'
import useInstruments from '@/composables/useInstruments'
import useMarketSessions from '@/composables/useMarketSessions'
import InstrumentDropdown from '@/views/components/InstrumentDropdown.vue'
import useRates from '@/composables/rates/useRates'
import useClock from '@/composables/useClock'
import Loader from '../../components/Loader.vue'
import activeTradeOverlay from '../../components/tvchart/overlays/activeTradeOverlay.js'
// import rangeSelectionTool from '../../components/tvchart/tools/rangeSelectionTool.js'

export default {
  name: 'TradingChart',
  directives: { Ripple },
  components: {
    TradingVue,
    ResizeObserver,
    Loader,
    InstrumentDropdown,
  },
  setup() {
    const { setInstrumentActive, instrumentActive, instrumentList } = useInstruments()
    const { clockTime, setClockTime } = useClock()
    const {
      marketOpenStatus,
    } = useMarketSessions()
    const {
      getRates,
      ratesWebsocket,
      ratesLive,
      ratesLoading,
    } = useRates()

    if (ratesLive.value.length < 700) {
      // Historic rates
      getRates({ loadType: 'blocking' })
    }

    watch(instrumentActive.value, () => {
      // Historic rates on Instrument change
      if (ratesLive.value.length < 700) {
        getRates({ loadType: 'blocking' })
      }
    })

    if (marketOpenStatus.value && !ratesWebsocket.isOpen) {
      // Live rates for all instruments
      ratesWebsocket.open()
      ratesWebsocket.setOpen()
    }

    // handle chart size/responsiveness
    const chartWidth = ref(0)
    const chartHeight = ref(0)

    watch(() => ratesLive.value, () => {
      chartWidth.value = document.getElementsByClassName('chart-wrapper')[0].clientWidth
      chartHeight.value = document.getElementsByClassName('chart-wrapper')[0].clientHeight
    })

    function handleResize({ width, height }) {
      chartWidth.value = width
      chartHeight.value = height
    }

    onMounted(() => {
      chartWidth.value = document.getElementsByClassName('chart-wrapper')[0].clientWidth
      chartHeight.value = document.getElementsByClassName('chart-wrapper')[0].clientHeight
    })

    const clockInterval = setInterval(() => setClockTime(), 1000)
    onUnmounted(() => {
      clearInterval(clockInterval)
    })

    return {
      setInstrumentActive,
      instrumentActive,
      instrumentList,
      clockTime,
      ratesLive,
      ratesLoading,
      getRates,
      //
      marketOpenStatus,
      chartWidth,
      chartHeight,
      handleResize,
    }
  },
  data() {
    return {
      rateInterval: null,
      clockInterval: null,
      tv: null,
      config: {
        DEFAULT_LEN: 400,
        MINIMUM_LEN: 30,
        CANDLEW: 0.5,
        GRIDX: 100,
        GRIDY: 100,
        SBMIN: 10,
        VOLSCALE: 0.3,
        TOOLBAR: 42,
        TB_ICON: 24,
        TB_ITEM_M: 0,
      },
      overlays: [activeTradeOverlay],
      displayJournalTradesOverlay: true,
      dc: new DataCube({
        chart: {
          data: this.ratesLive,
          tf: '15m',
        },
        onchart: [
          // {
          //   name: 'Journal Trades',
          //   type: 'journalTradesOverlay',
          //   data: [],
          //   settings: {
          //     display: this.displayJournalTradesOverlay,
          //     'z-index': 10,
          //   },
          // },
          {
            name: 'Plot Signal',
            type: 'activeTradeOverlay',
            data: [],
            settings: {
              'z-index': 0,
              signal: this.singleSignalPlot,
            },
          },
        ],
        offchart: [],
      }),
    }
  },
  computed: {
    colors() {
      return this.$isDark.value
        ? {
          // dark theme
          back: '#0e0e0e',
          grid: '#292929',
          text: '#35a776',
          cross: '#fff60',
          candle_up: '#236d3a',
          candle_dw: '#673436',
          wick_up: '#236d3a',
          wick_dw: '#673436',
          volume_up: '#57c4783a',
          volume_dw: '#f56e7434',
        }
        : {
          // light theme
          back: '#fff',
          grid: '#eee',
          text: '#333',
          candle_up: '#57C478',
          candle_dw: '#f56e74',
          wick_up: '#57C478',
          wick_dw: '#f56e74',
          volume_up: '#57c4783a',
          volume_dw: '#f56e7434',
        }
    },
    chartLoaded() {
      let chart
      if (this.$refs.tradingVue) {
        chart = this.$refs.tradingVue
      }
      return chart
    },
  },
  watch: {
    // chart() {
    //   this.$emit('redraw')
    // },
    ratesLive() {
      if (this.marketOpenStatus.value) {
        this.dc.set('chart.data', this.ratesLive)
      } else {
        setTimeout(() => this.dc.set('chart.data', this.ratesLive), 200)
      }
    },
  },
  mounted() {
    // dirty chart fix for remount
    setTimeout(() => {
      this.resetChart()
    }, 500)
  },
  methods: {
    displaySignals() {
      const signalsIcon = document.getElementById('displaySignals')
      if (this.displayJournalTradesOverlay) {
        signalsIcon.classList.remove('clicked')
        this.displayJournalTradesOverlay = false
      } else {
        this.displayJournalTradesOverlay = true
        signalsIcon.classList.add('clicked')
      }
    },
    checkRateRange() {
      if (this.$refs.tradingVue.$refs.chart.ohlcv.length > 0) {
        const firstVisibleCandleIndex = this.$refs.tradingVue.$refs.chart.sub_start
        if (firstVisibleCandleIndex < 100) {
          this.getRates({ loadType: 'passive' })
        }
      }
    },
    expandGrid() {
      const gridPane = document.getElementsByClassName('grid-pane')[0]
      const chartPane = document.getElementsByClassName('chart-pane')[0]
      const chartIcon = document.getElementById('expandChart')
      const gridIcon = document.getElementById('expandGrid')

      if (gridPane.classList.contains('expanded')) {
        gridPane.classList.remove('expanded')
        chartPane.classList.remove('collapsed')
        gridIcon.classList.remove('clicked')
      } else {
        gridPane.classList.add('expanded')
        gridPane.classList.remove('collapsed')
        chartPane.classList.add('collapsed')
        chartPane.classList.remove('expanded')
        chartIcon.classList.remove('clicked')
        gridIcon.classList.add('clicked')
      }
    },
    expandChart() {
      const gridPane = document.getElementsByClassName('grid-pane')[0]
      const chartPane = document.getElementsByClassName('chart-pane')[0]
      const chartIcon = document.getElementById('expandChart')
      const gridIcon = document.getElementById('expandGrid')

      if (chartPane.classList.contains('expanded')) {
        chartPane.classList.remove('expanded')
        gridPane.classList.remove('collapsed')
        chartIcon.classList.remove('clicked')
      } else {
        gridPane.classList.add('collapsed')
        gridPane.classList.remove('expanded')
        chartPane.classList.add('expanded')
        chartPane.classList.remove('collapsed')
        chartIcon.classList.add('clicked')
        gridIcon.classList.remove('clicked')
      }
    },
    resetChart() {
      if (this.$refs.tradingVue) {
        this.$refs.tradingVue.resetChart()
        this.width = document.getElementsByClassName('chart-wrapper')[0].clientWidth
        this.height = document.getElementsByClassName('chart-wrapper')[0].clientHeight
      }
    },
  },
}
</script>

<style lang="scss">
.trading-graph {
  cursor: crosshair;
}

.resize-observer {
  width: 100%;
  height: 100%;
}
.chart-component {
  width: 100%;
  height: 100%;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  .chart-wrapper {
    width: 100%;
    height: calc(100% - 36px);
    display: flex;
    position: relative;
  }
}

// chart topbar
.instrument-dropdown-content {
  position: relative;
  z-index: 3333;
}
.trading-vue-chart {
  position: relative;
  height: 100%;
  .trading-vue-legend {
    max-width: 45% !important;
    z-index: 1;
    .t-vue-title {
      display: none;
    }
  }
  .trading-vue-grid-0 {
    cursor: none;
  }
  .t-vue-ind {
    display: none;
  }
  .trading-vue-botbar {
    #trading-vue-js-botbar-canvas {
      height: 100%;
    }
  }
}

// toolbar

.trading-vue-toolbar {
  position: absolute;
  border-right: 1px solid black;
  z-index: 101;
  top: 0;
  width: 100%;
  padding-top: 3px;
  user-select: none;
}

// dark layout

.dark-layout {
  .chart-top-bar {
    background: #0e0e0e;
    border-bottom: 1px solid hsl(0, 0%, 21%);
    .chart-option {
      .chart-icon {
        border-color: hsl(0, 0%, 21%);
        &.clicked {
          color: rgb(46, 139, 85);
        }
      }
    }
    .hermes-warning {
      background-color: rgb(129, 120, 0);
    }
    .instrument-dropdown {
      border-right: 1px solid hsl(0, 0%, 21%);
      ::after {
        background-color: hsl(0, 0%, 21%) !important;
      }
      .btn-secondary {
        color: rgb(219, 219, 219) !important;
      }
    }
  }
}
</style>
