<template>
  <div>
    <div class="trading-page">
      <multipane
        class="trading-pane"
        layout="horizontal"
      >
        <span class="tool-tip">
          <a
            class="tool-tip-link"
            href="#"
            data-beacon-article-sidebar="60ee79c661c60c534bd6f69d"
          />
          <!-- <i class="far fa-question-circle" /> -->
        </span>
        <div class="pane chart-pane">
          <TradingChart />
        </div>
        <multipane-resizer />
        <div
          class="pane grid-pane"
          :style="{ flexGrow: 1 }"
        >
          <tabs
            id="full-tabs"
            class="no-border"
          >
            <template v-slot:after>
              <div class="instrument-filter">
                <input
                  id="signalFilter"
                  type="checkbox"
                  name="filter"
                  :checked="instrumentFilter"
                  @click="toggleInstrumentFilter($event.srcElement.checked)"
                >
                <label
                  class="filter-label"
                  for="filter"
                >{{ instrumentActive.name.replace(/_/g, "/") }} Only</label>
              </div>
              <div
                class="market-wrap"
              >
                <div
                  v-if="marketOpenStatus"
                  class="session-wrap"
                >
                  <p class="session prev">
                    {{ marketPrevSession.name }} {{ prevSession }} ago
                  </p>
                  <p class="session next">
                    {{ marketNextSession.name }} in {{ nextSession }}
                  </p>
                </div>
                <p
                  v-else
                  class="market-closed"
                >
                  Markets Open In: {{ marketOpensIn.days }}:{{ marketOpensIn.hours }}:{{ marketOpensIn.minutes }}:{{ marketOpensIn.seconds }}
                </p>
              </div>
            </template>
            <tab :index="0">
              <GridSetup
                v-if="marketOpenStatus === true"
                :pagination="false"
                :processed-data="signals"
              />
              <div
                v-else
                class="weekend-game"
              >
                <iframe
                  id="gameFrame"
                  src="https://funhtml5games.com?embed=2048bit"
                  style="width: 100%; height: 100%; border: none"
                  frameborder="0"
                  scrolling="no"
                />
              </div>
            </tab>
            <tab
              class="tab-pane"
              :index="1"
            >
              <GridSetup
                :pagination="false"
                :processed-data="positions"
              />
            </tab>
            <tab
              class="tab-pane"
              :index="2"
            >
              <GridSetup
                :pagination="false"
                :processed-data="journalTrades"
              />
            </tab>
          </tabs>
        </div>
        <MetricsGrid
          :requested-metrics="'journal100'"
          :grid-type="'small'"
          :metrics="journalMetrics"
        />
      </multipane>
    </div>
  </div>
</template>

<script>
import { Multipane, MultipaneResizer } from 'vue-multipane'
import { onUnmounted, ref } from '@vue/composition-api'
import TradingChart from '@/views/pages/trading/TradingChart.vue'
import useInstruments from '@/composables/useInstruments'
import useTabs from '@/composables/useTabs'
import useTradePlot from '@/composables/trades/useTradePlot'
import useSignals from '@/composables/signals/useSignals'
import useJournalTrades from '@/composables/journal/useJournalTrades'
import useMarketSessions from '@/composables/useMarketSessions'
import usePermissions from '@/composables/usePermissions'
import useRates from '@/composables/rates/useRates'
import useClock from '@/composables/useClock'
import MetricsGrid from '../../components/metrics/MetricsGrid.vue'
import GridSetup from '../../components/agGrid/GridSetup.vue'
import tab from '../../components/appTab.vue'
import tabs from '../../components/appTabs.vue'

export default {
  name: 'Trading',
  components: {
    TradingChart,
    GridSetup,
    Multipane,
    MultipaneResizer,
    MetricsGrid,
    tab,
    tabs,
  },
  setup() {
    const {
      setInstrumentActive,
      instrumentActive,
      instrumentList,
      instrumentFilter,
      toggleInstrumentFilter,
    } = useInstruments()

    const {
      journalTrades,
      journalMetrics,
      journalPositions: positions,
      journalWebsocket,
      takePosition,
      closePosition,
    } = useJournalTrades()

    const {
      createMarketSessions,
      marketNextSession,
      marketPrevSession,
      marketOpenStatus,
      marketOpensIn,
      checkMarketIsOpen,
    } = useMarketSessions()

    createMarketSessions()

    if (!journalWebsocket.isOpen) {
      journalWebsocket.open()
      journalWebsocket.setOpen()
    }

    const {
      signals,
      signalsWebsocket,
    } = useSignals()

    if (!signalsWebsocket.isOpen && marketOpenStatus.value) {
      signalsWebsocket.open()
      signalsWebsocket.setOpen()
    }

    const { setTabActive, tabPage, tabActive } = useTabs()
    const { access } = usePermissions()
    const { getLatestRate } = useRates()
    const { setTradePlotType } = useTradePlot()

    const { setCountup } = useClock()

    async function takeTradeEvent(data) {
      const trade = data.detail
      const canAccess = await access.feature('takeTrade')
      if (canAccess) {
        takePosition({ trade })
      }
    }
    async function closeTradeEvent(data) {
      const trade = data.detail
      const latestRate = getLatestRate({ instrument: trade.instrument })
      closePosition({ trade, latestRate })
    }

    async function plotTradeEvent(data) {
      const trade = data.detail
      setTradePlotType(trade)
      // plotTrade({ trade })
    }

    let nextSessionInterval
    let prevSessionInterval
    let marketsOpenInterval
    const nextSession = ref()
    const prevSession = ref()

    if (marketOpenStatus.value) {
      nextSession.value = setCountup({ time: marketNextSession.value.date, type: 'minute' })
      nextSessionInterval = setInterval(() => {
        nextSession.value = setCountup({ time: marketNextSession.value.date, type: 'minute' })
      }, 60000)

      prevSession.value = setCountup({ time: marketPrevSession.value.date, type: 'minute' })
      prevSessionInterval = setInterval(() => {
        prevSession.value = setCountup({ time: marketPrevSession.value.date, type: 'minute' })
      }, 60000)
    } else {
      marketsOpenInterval = setInterval(() => checkMarketIsOpen(), 1000) // 1 minute
    }

    document.addEventListener('take-trade', takeTradeEvent)
    document.addEventListener('close-trade', closeTradeEvent)
    document.addEventListener('plot-trade', plotTradeEvent)

    onUnmounted(() => {
      clearInterval(marketsOpenInterval)
      clearInterval(nextSessionInterval)
      clearInterval(prevSessionInterval)
      document.removeEventListener('take-trade', takeTradeEvent)
      document.removeEventListener('close-trade', closeTradeEvent)
      document.removeEventListener('plot-trade', plotTradeEvent)
    })

    return {
      setInstrumentActive,
      toggleInstrumentFilter,
      instrumentFilter,
      instrumentActive,
      instrumentList,
      // signals
      signals,
      // tabs
      setTabActive,
      tabPage,
      tabActive,
      // market sessions
      marketOpenStatus,
      marketOpensIn,
      marketNextSession,
      marketPrevSession,
      nextSession,
      prevSession,
      // journal
      journalTrades,
      journalMetrics,
      // positions
      positions,
    }
  },
}
</script>

<style lang="scss" >
label {
  font-weight: normal !important;
}

.no-nav {
  display: none;
}

.toolbar button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
}

.multipane > div {
  position: initial;
  z-index: initial;
}

.trading-page {
  height: 100vh;
  background: #f3f3f3;
  .trading-pane {
    position: relative;
    padding-top: 4.55rem;
    height: 100%;
    border-left: 5px solid hsl(0, 0%, 95%);
    border-right: 5px solid hsl(0, 0%, 95%);
    .chart-pane {
      height: 65vh;
      max-height: 100%;
      min-height: 36px;
      &.expanded {
        height: 100% !important;
      }
      &.collapsed {
        height: 31px !important;
      }
    }
    .multipane-resizer {
      background: #e9e9e9;
      z-index: 2;
      height: 5px;
      min-height: 5px;
      margin: 0;
      top: 0px;
    }
    .grid-pane {
      z-index: 1;
      max-height: 100%;
      width: 100%;
      display: flex;
      #full-tabs {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: rgb(243, 243, 243);
        .tab-content {
          width: 100%;
          height: 100%;
        }
        .weekend-game {
          position: relative;
          min-height: 0px;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-color: #faf8ef;
          overflow: scroll;
          #gameFrame {
            padding-top: 2rem;
            position: absolute;
            min-height: 740px;
          }
        }
      }
      &.expanded {
        height: 100% !important;
      }
      &.collapsed {
        height: 45px !important;
      }
    }
  }
}

.market-wrap {
  display: flex;
  height: 46px;
  align-items: center;
  margin-left: 1rem;
  font-size: 12px;
  .session-wrap {
    display: flex;
    align-items: center;
    .session {
      margin-right: 1rem;
      margin-bottom: 0;
      font-weight: 500;
      white-space: nowrap;
      // color: white;
      border-radius: 1rem;
      padding: 0px 8px 0px 8px;
      &.next {
        border: 2px solid #55c376;
      }
      &.prev {
        border: 2px solid #f57077;
      }
    }
  }
  .market-closed {
    margin: 0;
    border: 2px solid #f57077;
    padding: 0px 8px 0px 8px;
    border-radius: 1rem;
  }
}

// trading page dark layout
.dark-layout {
  .layout-h .multipane-resizer {
    background: #347447;
  }
  .trading-pane {
    border-left: 5px solid hsl(0, 0%, 12%);
    border-right: 5px solid hsl(0, 0%, 12%);
    .multipane-resizer {
      background: #236d3a;
    }
  }
  .weekend-game {
    background-color: hsl(0, 0%, 12%);
  }
}
</style>
