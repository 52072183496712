// for plotting trades on the chart
// make sure you understand HTML5 canvas when editing this file

// import { toPIPs } from '@/mixins/toPIPs'
import useJournalTrades from '@/composables/journal/useJournalTrades'
import useInstruments from '@/composables/useInstruments'
import useTradePlot from '@/composables/trades/useTradePlot'
import { Overlay } from 'trading-vue-js'

export default {
  name: 'activeTradeOverlay',
  setup() {
    const { instrumentActive } = useInstruments()
    const { journalPositions: positions } = useJournalTrades()
    const { plotTrade } = useTradePlot()

    return {
      positions,
      instrumentActive,
      plotTrade,
    }
  },
  mixins: [Overlay],
  methods: {
    draw(ctx) {
      const context = ctx
      const { layout } = this.$props // Layout object (see trading vue API BOOK)

      // colors
      const entryColor = '#b8b8b8' // hsla(0, 100%, 79%, 0.32)
      const slColor = 'hsla(0, 100%, 79%, 0.72)' //
      const tpColor = '#0fd667' // hsla(137, 100%, 79%, 0.32)

      // historic trades
      // eslint-disable-next-line arrow-body-style
      if (this.positions.length > 0) {
        // filter by instrument, then for each
        this.positions.filter(trade => trade.instrument === this.instrumentActive.name)
        this.positions.forEach(trade => {
          const sl = layout.$2screen(trade.sl) // Mapping sl price (y)
          const tp = layout.$2screen(trade.tp) // Mapping tp price (y)
          const entry = layout.$2screen(trade.entryPrice.user) // Mapping tp price (y)
          // Entry
          context.setLineDash([])
          context.lineWidth = 1
          context.strokeStyle = entryColor
          context.beginPath()
          context.moveTo(0, entry)
          context.lineTo(10000, entry)
          context.stroke()
          // Stop loss
          context.setLineDash([8, 3])
          context.strokeStyle = slColor
          context.beginPath()
          context.moveTo(0, sl)
          context.lineTo(10000, sl)
          context.stroke()
          // take profit
          context.strokeStyle = tpColor
          context.beginPath()
          context.moveTo(0, tp)
          context.lineTo(10000, tp)
          context.stroke()
          // this seems to run more than it should
        })
      }
      // this seems to run more than it should
    },
    use_for() { return ['activeTradeOverlay'] },
  },
  // Define internal setting & constants here
  computed: {
    isDark() {
      return this.$props.settings.isDark
    },
  },
}
