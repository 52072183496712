<template>
  <section
    class="ddwn-instrument-wrap"
  >
    <div
      class="ddwn-instrument-selected-item"
    >
      <input
        v-model="inst"
        type="text"
      >
    </div>
    <div
      class="ddwn-instrument-popover"
    >
      <div
        class="ddwn-instrument-options"
      >
        <ul>
          <li
            v-for="instrument in instrumentList"
            :key="instrument.id"
            @click="setInstrument(instrument)"
          > {{ instrument.name.replace('_', '/') }}</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>

import useInstruments from '@/composables/useInstruments'
import { computed } from '@vue/composition-api'

export default {
  name: 'InstrumentDropdown',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: String,
      default: 'test',
    },
  },
  setup() {
    const {
      instrumentList,
      instrumentActive,
      getInstruments,
      setInstrumentActive,
    } = useInstruments()

    getInstruments()

    const inst = computed(() => instrumentActive.value.name.replace('_', '/'))

    console.log('instrumentActive', instrumentActive)

    return {
      instrumentList,
      inst,
      setInstrumentActive,
    }
  },
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    setInstrument(inst) {
      this.setInstrumentActive({ newInst: inst })
    },
  },
}
</script>

<style lang="scss">

.ddwn-instrument-wrap {
  position: static;
  min-width: 140px;
  font-size: 12px;
  &.small {
    height: 100%;
    border-right: 1px solid #f0f0f0;
  }
  &.normal {
    height: 42px;
    // border-right: 5px solid #F3F3F3;
    // .ddwn-instrument-popover {
    //   top: 47px;
    // }
  }
  &:hover {
    .ddwn-instrument-popover {
      display: block;
    }
  }
  width: 120px;
  .ddwn-instrument-selected-item {
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;
    border: none;
    border-radius: 5px;
    height: 100%;
    input {
      border: none;
      width: 100%;
      height: 100%;
      text-align: center;
      border-radius: 5px;
      &:focus {
        outline: none;
      }
    }
  }

  .ddwn-instrument-popover {
    position: absolute;
    display: none;
    border: 1px solid #f0f0f0;
    border-top: none;
    width: 260px;
    max-height: 240px;
    background-color: white;
    overflow-y: scroll;
    z-index: 100;
    margin: 0;
    box-shadow: 5px 6px 20px 5px hsla(0, 0%, 0%, 0.10);
    &::-webkit-scrollbar {
      display: block;
      width: 16px;
    }
  }

  .ddwn-instrument-options {
    width: 100%;
    ul {
      list-style: none;
      text-align: left;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        border-bottom: 1px solid #f0f0f0;
        padding: 1rem;
        margin: none;
        &:hover {
          background-color: rgb(245, 245, 245);
        }
      }
    }
  }
}

</style>
