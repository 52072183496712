<template>
  <div
    class="loader"
  >
    <div
      class="loading"
    >
      <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<style scoped>
.loader{
  min-height: 50vh
}
</style>
